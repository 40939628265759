import React from "react"
import Gallery from "../../components/Gallery/"
import NameHeader from "../../components/NameHeader"

function Works() {
  return (
    <main>
      <NameHeader />
      <Gallery />
    </main>
  )
}

export default Works
