import React from "react"
import Presentation from "../../components/Presentation/"

function Accueil() {
  return (
    <main>
      <Presentation />
    </main>
  )
}

export default Accueil
