import React from "react"

import "./NameHeader.scss"

function NameHeader() {
  return (
    <h1 className="my_name">
      Ibtihaj BOUCHIRAB <span>Développeuse Web</span>
    </h1>
  )
}

export default NameHeader
