import React from "react"
import MsgError from "../../components/MsgError/"

function Error() {
  return (
    <main>
      <MsgError />
    </main>
  )
}

export default Error
